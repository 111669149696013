import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";


const DietLayout = ({diet, image, children}) => {
  return (
    <>
      <section id="diet" className="diet">
        <div className="container">

          <div className="section-title">
            <h2>Dieta <span>{diet.title}</span></h2>
          </div>

          <div className="row row-diet">
            <div className="col-lg-6 col-diet">
              <div className="description">
                <div>
                  <span>
                    {children}
                    {diet.description}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-diet">
              <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={diet.title} className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section id="menu" className="menu">
        <div className="container">

          <div className="section-title">
            <h2>Przykładowe <span>Menu</span></h2>
          </div>

          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center">
            </div>
          </div>

          <div className="row menu-container">

            {diet.menu.map((meal) => (
              <div className="menu-item filter-starters" key={meal.name}>
                <div className="menu-content">
                  <a>{meal.name}</a><span>{meal.hour}</span>
                </div>
                <div className="menu-ingredients">
                  {meal.description}
                </div>
              </div>
            ))}

          </div>
        </div>
      </section>
    </>
  )
}

export default DietLayout
