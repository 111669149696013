import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import SmallHeroSection from "../components/SmallHeroSection"
import DecorativeSection from "../components/DecorativeSection"
import { graphql } from "gatsby"
import DietLayout from "../components/DietLayout"

export const query = graphql`
  {
    file(name: { eq: "diet1" }) {
      base
      publicURL
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`

var planWegeFish = {
  title: "Bez Glutenu",
  menu: [
    {
      name: "Śniadanie",
      hour: "7:00",
      description: "Owsianka kokosowo-orzechowa",
    },
    {
      name: "Drugie Śniadanie",
      hour: "10:00",
      description: "Ciasto owocowe",
    },
    {
      name: "Obiad",
      hour: "13:00",
      description: "Bezgluetnowa Pizza",
    },
    {
      name: "Podwieczorek",
      hour: "17:00",
      description: "Pomidor faszerowany tuńczykiem",
    },
    {
      name: "Kolacja",
      hour: "19:30",
      description: "Farrinata z czerwonym pesto",
    },
  ],
}

const PlanWithoutGlutenPage = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <Seo title="Diata Bez Glutenu" />
      <SmallHeroSection />
      <DecorativeSection />

      <DietLayout diet={planWegeFish} image={data.file}>
      Dieta skierowana dla osób z obniżoną tolerancją na gluten. Używamy w tym jadłospisie m.in. bezglutenowych produktów zbożowych, chude mięso, ryby, nabiał, owoce i warzywa. Produkty zawierające gluten zostały wyeliminowane.
      <br/>
       <b>Uwaga:</b> Na obiekcie produkcyjnym przyrządzane są posiłki ze wszystkich proponowanych przez naszą firmę diet, dlatego istnieje prawdopodobieństwo wystąpienia śladowych ilości glutenu w posiłkach. W związku z tym nie polecamy stosowania diety osobom chorym na celiakię.
      <br/>
       *Można ją połączyć z dietą bez laktozy.  
        </DietLayout>
    </Layout>
  )
}

export default PlanWithoutGlutenPage
